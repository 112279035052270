// Imports
import { Link } from 'react-router-dom';
import Box from '../../../shareables/foundation/simple-ui/components/box';


// Function component
const NotFound: React.FC = () => (
	<Box as='main'>
		We couldn’t find the page you were looking for. <Link to='/'>Click here</Link> to get back on track.
	</Box>
);

export default NotFound;
