// Imports
import React, { useCallback, useEffect } from 'react';
import { createBrowserRouter, createMemoryRouter, Outlet, RouteObject, RouterProvider } from 'react-router-dom';
import PageMessages from '../shareables/foundation/front-end/components/resources/page-messages';
import GlobalStyle from '../shareables/foundation/simple-ui/components/global-style';
import { GetWebsitesResponse } from '../shareables/types/api/main/app/websites/get';
import MyCommunities from './pages/my-communities';
import UserFunctionality from './resources/user-functionality';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Heading from './resources/heading';
import TOARequest from './pages/toa-request';
import NotFound from './pages/not-found';
import styled from 'styled-components/macro';
import useCachedState from '../shareables/foundation/front-end/utils/use-cached-state';
import { useAppDispatch } from '../shareables/foundation/front-end/redux/hooks';
import apiActions from '../shareables/integrations/api/redux/actions';
import TOAResponse from './pages/toa-response';
import { GetTOAResponse } from '../shareables/types/api/main/website/toa/get';


// Styled components
export const Content = styled.div`
	padding-bottom: 7rem;
`;


// Define the accepted props
interface Props {
	storybookWebsites?: GetWebsitesResponse;
	storybookRoute?: string;
	storybookTOARequest?: GetTOAResponse;
	storybookTOARequestNotFound?: boolean;
	storybookWebsiteID?: string;
}


// Function component
const App: React.FC<Props> = ({
	storybookWebsites,
	storybookRoute,
	storybookTOARequest,
	storybookTOARequestNotFound,
	storybookWebsiteID,
}) => {
	// Use state
	const [websites, setWebsites] = useCachedState<GetWebsitesResponse | null>(storybookWebsites ?? null, 'websites');
	
	
	// Use Redux functionality
	const dispatch = useAppDispatch();
	
	
	// Function that loads websites
	const memoizedLoadWebsites = useCallback(() => {
		return new Promise<void>((resolve) => {
			void dispatch(
				apiActions.call<undefined, undefined, GetWebsitesResponse>({
					action: 'GET',
					uri: '/app/websites',
					callBeforeFinish: resolve,
					completion: (json) => {
						setWebsites(json.response);
					},
				})
			);
		});
	}, [dispatch, setWebsites]);
	
	
	// Load websites on mount
	useEffect(() => {
		void memoizedLoadWebsites();
	}, [memoizedLoadWebsites]);
	
	
	// Build inner content
	const innerContent = (
		<React.Fragment>
			{!(window as typeof window & { STORYBOOK?: true }).STORYBOOK && (
				<React.Fragment>
					<PageMessages />
					<UserFunctionality />
				</React.Fragment>
			)}
			
			<Helmet defaultTitle='App // HOA Express' titleTemplate='%s // HOA Express' />
			
			<Heading />
			
			<Content>
				<Outlet />
			</Content>
		</React.Fragment>
	);
	
	
	// Use React Router functionality
	const routes: RouteObject[] = [
		{
			path: '/',
			element: innerContent,
			children: [
				{
					index: true,
					element: <MyCommunities websites={storybookWebsites ?? websites} onChange={memoizedLoadWebsites} />,
					handle: {
						title: 'My communities',
					},
				},
				{
					path: 'toa',
					children: [
						{
							path: 'request',
							element: <TOARequest websites={storybookWebsites ?? websites} />,
							handle: {
								title: 'TOA request',
							},
						},
						{
							path: 'response',
							element: (
								<TOAResponse
									onChange={memoizedLoadWebsites}
									storybookTOARequest={storybookTOARequest}
									storybookNotFound={storybookTOARequestNotFound}
									storybookWebsiteID={storybookWebsiteID}
								/>
							),
							handle: {
								title: 'TOA request',
							},
						},
					],
				},
				{
					path: '*',
					element: <NotFound />,
					handle: {
						title: 'Not found',
					},
				},
			],
		},
	];
	
	const router = (window as typeof window & { STORYBOOK?: boolean }).STORYBOOK
		? createMemoryRouter(routes, { initialEntries: [storybookRoute ?? '/'] })
		: createBrowserRouter(routes);
	
	
	// Return JSX
	return (
		<React.StrictMode>
			<GlobalStyle />
			
			<HelmetProvider>
				<RouterProvider router={router} />
			</HelmetProvider>
		</React.StrictMode>
	);
};

export default App;
