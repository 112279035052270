// Imports
import { createGlobalStyle } from 'styled-components/macro';


// Define global style
const GlobalAPIFormStyle = createGlobalStyle`
	.grecaptcha-badge {
		visibility: hidden;
	}
`;

export default GlobalAPIFormStyle;
