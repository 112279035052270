// Imports
import { faArrowsRotate } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import ButtonGroup from '../../../shareables/foundation/front-end/components/buttons/group';
import commonActions from '../../../shareables/foundation/front-end/redux/actions';
import { useAppDispatch } from '../../../shareables/foundation/front-end/redux/hooks';
import Box from '../../../shareables/foundation/simple-ui/components/box';
import APIButton, { APIButtonOptions } from '../../../shareables/integrations/api/components/button';
import apiActions from '../../../shareables/integrations/api/redux/actions';
import { GetTOAResponse } from '../../../shareables/types/api/main/website/toa/get';
import Subheading from '../../resources/subheading';


// Styled components
const Heading = styled(Subheading)`
	margin-bottom: 0.625rem;
`;

const Explanation = styled.p`
	line-height: 1.3;
	
	&:not(:last-child) {
		margin-bottom: 1.25rem;
	}
	
	> a {
		color: inherit;
		text-decoration: underline;
		cursor: pointer;
		
		&:hover {
			color: inherit;
			text-decoration: none;
		}
	}
	
	> strong {
		font-weight: 700;
	}
`;

const LoadingWrapper = styled.div`
	text-align: center;
	font-style: italic;
	margin: auto;
`;


// Define the accepted props
interface Props {
	onChange: () => Promise<void>;
	storybookTOARequest?: GetTOAResponse;
	storybookNotFound?: boolean;
	storybookWebsiteID?: string;
}


// Function component
const TOAResponse: React.FC<Props> = ({ onChange, storybookTOARequest, storybookNotFound, storybookWebsiteID }) => {
	// Use state
	const [toaRequest, setTOARequest] = useState<GetTOAResponse | null>(storybookTOARequest ?? null);
	const [notFound, setNotFound] = useState(storybookNotFound ?? false);
	
	
	// Use Redux functionality
	const dispatch = useAppDispatch();
	
	
	// Use React Router functionality
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const websiteID = storybookWebsiteID ?? searchParams.get('websiteID');
	
	
	// Handle completion
	const handleCompletion = (action: 'APPROVE' | 'DENY') => {
		if (!toaRequest) {
			throw new Error('This should not be called before the TOA request loads');
		}
		
		dispatch(
			commonActions.showPageMessage({
				title: 'Success!',
				message: `The transfer of administration request for “${toaRequest.communityName}” has been ${
					action === 'APPROVE' ? 'approved.' : 'denied.'
				}`,
				color: 'success',
			})
		);
		
		return onChange().then(() => {
			navigate('/');
		});
	};
	
	
	// Load TOA Request on mount
	useEffect(() => {
		if (!websiteID) {
			setNotFound(true);
			
			return;
		}
		
		void dispatch(
			apiActions.call<undefined, undefined, GetTOAResponse>({
				action: 'GET',
				uri: `/website/${websiteID}/toa`,
				completion: (json) => {
					if (json.status === 'error') {
						setNotFound(true);
					} else {
						setTOARequest(json.response);
					}
				},
				handleErrorsAutomatically: false,
			})
		);
	}, [websiteID, dispatch]);
	
	
	// Build button options
	const approveButtonOptions: APIButtonOptions<undefined, undefined, undefined> = {
		action: 'APPROVE',
		uri: `/website/${websiteID ?? ''}/toa`,
		warning: {
			message: `This will remove your administrative privileges and transfer them to ${toaRequest?.fullName ?? ''}.`,
			button: 'Yes, approve request',
		},
	};
	
	const denyButtonOptions: APIButtonOptions<undefined, undefined, undefined> = {
		action: 'DENY',
		uri: `/website/${websiteID ?? ''}/toa`,
		warning: {
			message: 'This will cancel the request and no change will be made to the administrators.',
			button: 'Yes, deny request',
		},
	};
	
	return (
		<React.Fragment>
			{toaRequest && (
				<Box>
					<Heading>Respond to TOA request</Heading>
					
					<Explanation>
						Do you approve transferring your administrative privileges to <strong>{toaRequest.fullName}</strong> on the{' '}
						<a rel='noopener noreferrer' target='_blank' href={toaRequest.url}>
							{toaRequest.communityName}
						</a>{' '}
						website?
					</Explanation>
					
					<ButtonGroup>
						<APIButton options={approveButtonOptions} onCompletion={() => handleCompletion('APPROVE')}>
							Approve
						</APIButton>
						
						<APIButton options={denyButtonOptions} onCompletion={() => handleCompletion('DENY')} color='danger'>
							Deny
						</APIButton>
					</ButtonGroup>
				</Box>
			)}
			
			{notFound && (
				<Box>
					<Heading>Request not found</Heading>
					
					<Explanation>The TOA request couldn’t be found for this website.</Explanation>
				</Box>
			)}
			
			{!notFound && !toaRequest && (
				<Box>
					<LoadingWrapper>
						<FontAwesomeIcon icon={faArrowsRotate} spin fixedWidth /> Loading…
					</LoadingWrapper>
				</Box>
			)}
		</React.Fragment>
	);
};

export default TOAResponse;
