// Imports
import React from 'react';
import theme from '../../../theme';
import styled from 'styled-components/macro';
import { setSaturation, setLightness } from 'polished';


// Styled components
const Container = styled.header`
	margin: 0;
	padding: 7rem 0 1.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	
	@media (max-width: ${theme.breakpoints.mobile}px) {
		padding-top: 3rem;
	}
`;

const Logo = styled.div`
	margin: auto 1.5rem auto 0;
	display: block;
	vertical-align: middle;
	width: 40%;
	max-width: 200px;
	
	> svg,
	img {
		width: 100%;
	}
	
	@media (max-width: ${theme.breakpoints.mobile}px) {
		margin-right: 1rem;
	}
`;

const H1 = styled.h1`
	margin: auto 0;
	padding: 0 0 0 1.5rem;
	border-left: 1px solid ${setSaturation(0.1, setLightness(0.8, theme.colors.primary))};
	font-size: 2em;
	font-weight: 300;
	color: ${setSaturation(0.25, setLightness(0.24, theme.colors.primary))};
	
	@media (max-width: ${theme.breakpoints.mobile}px) {
		font-size: 1.5em;
		padding-left: 1rem;
	}
`;


// Define the accepted props
interface Props {
	logoElement: React.ReactElement;
	title: string;
}


// Function component
const Header: React.FC<Props> = ({ logoElement, title }) => {
	return (
		<Container>
			<Logo>{logoElement}</Logo>
			
			<H1>{title}</H1>
		</Container>
	);
};

export default Header;
