// Imports
import React from 'react';
import { faArrowUpRightFromSquare, faToggleOff } from '@fortawesome/pro-regular-svg-icons';
import { faArrowsRotate } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';
import AnchorButton from '../../../shareables/foundation/front-end/components/buttons/anchor';
import Box from '../../../shareables/foundation/simple-ui/components/box';
import APIButton, { APIButtonOptions } from '../../../shareables/integrations/api/components/button';
import { GetWebsitesResponse } from '../../../shareables/types/api/main/app/websites/get';
import Subheading from '../../resources/subheading';


// Styled components
const Communities = styled.div`
	display: flex;
	max-width: 53.5rem;
	width: 100%;
	justify-content: center;
	gap: 1.5rem;
	flex-wrap: wrap;
	margin: auto;
	
	padding-left: 1rem;
	padding-right: 1rem;
`;

const CommunityContainer = styled.div`
	width: calc(50% - 0.75rem);
	
	&:only-child {
		width: 50%;
	}
	
	@media (max-width: 48rem) {
		&& {
			width: 100%;
			max-width: 25rem;
		}
	}
`;

const Community = styled.div<{ screenshot?: string }>`
	width: 100%;
	padding-top: 50%;
	
	position: relative;
	overflow: hidden;
	
	background-color: hsl(0 0% 100%);
	
	&::before {
		content: '';
		
		position: absolute;
		top: 0;
		left: 0;
		
		width: 100%;
		padding-top: 50%;
		
		background-image: linear-gradient(to top, hsl(0 0% 100%) 0%, transparent 26%),
			url(${({ screenshot }) => screenshot ?? '/images/fallback.svg'});
		background-size: contain;
		background-repeat: no-repeat;
	}
	
	border-radius: 0.1875rem;
	box-shadow: 0 0.125rem 0.25rem 0 hsl(0 0% 0% / 0.1);
`;

const Details = styled.div`
	text-align: center;
	display: flex;
	padding: 1.5rem;
	flex-direction: column;
	align-items: center;
	gap: 0.625rem;
	align-self: stretch;
	
	svg {
		font-size: 0.75rem;
	}
`;

const ButtonGroup = styled.div`
	display: flex;
	gap: 0.75rem;
	flex-wrap: wrap;
	justify-content: center;
`;

const LoadingWrapper = styled.div`
	text-align: center;
	font-style: italic;
`;


// Define the accepted props
interface Props {
	websites: GetWebsitesResponse | null;
	onChange: () => Promise<void>;
}


// Function components
const MyCommunities: React.FC<Props> = ({ websites, onChange }) => {
	// Map inactive status to human readable text
	const inactiveBecauseMap: Record<Required<GetWebsitesResponse[0]>['inactiveBecause'], string> = {
		ADMIN_DISABLED: 'disabled',
		DMCA_TAKEN_DOWN: 'DMCA takedown',
		PENDING_DELETION: 'pending deletion',
	};
	
	return (
		<Communities>
			{websites?.map((website) => {
				// Build button options
				const buttonOptions: APIButtonOptions<undefined, undefined, undefined> = {
					action: 'REACTIVATE',
					uri: `/website/${website.id}`,
				};
				
				
				// Return JSX
				return (
					<CommunityContainer key={website.id}>
						<Community screenshot={website.screenshot}>
							<Details>
								<Subheading>
									{website.communityName}
									{website.inactiveBecause && (
										<React.Fragment>
											{' '}
											<em>({inactiveBecauseMap[website.inactiveBecause]})</em>
										</React.Fragment>
									)}
								</Subheading>
								{(!website.inactiveBecause ||
									(website.inactiveBecause !== 'DMCA_TAKEN_DOWN' && website.privileges.includes('*'))) && (
									<ButtonGroup>
										{website.inactiveBecause ? (
											<APIButton color='info' options={buttonOptions} onCompletion={onChange}>
												<span>Re-enable</span>
												<FontAwesomeIcon icon={faToggleOff} />
											</APIButton>
										) : (
											<React.Fragment>
												<AnchorButton href={website.url}>
													<span>Visit website</span>
													<FontAwesomeIcon icon={faArrowUpRightFromSquare} />
												</AnchorButton>
												{website.privileges.length > 0 && (
													<AnchorButton
														color='link'
														href={`https://admin.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}/dashboard?websiteID=${website.id}`}
													>
														<span>Admin Portal</span>
														<FontAwesomeIcon icon={faArrowUpRightFromSquare} />
													</AnchorButton>
												)}
											</React.Fragment>
										)}
									</ButtonGroup>
								)}
							</Details>
						</Community>
					</CommunityContainer>
				);
			})}
			
			{websites?.length === 0 && (
				<Box>
					<em>You are not currently a member of any community website that uses HOA Express.</em>
				</Box>
			)}
			
			{websites === null && (
				<LoadingWrapper>
					<FontAwesomeIcon icon={faArrowsRotate} spin fixedWidth /> Loading…
				</LoadingWrapper>
			)}
		</Communities>
	);
};

export default MyCommunities;
