// Imports
import styled, { css } from 'styled-components/macro';
import { transparentize, darken, setLightness } from 'polished';
import theme from '../../../../../shareables/theme';
import React, { ButtonHTMLAttributes } from 'react';


// Built-in colors lookup
export const genericColorLookup = {
	plain: '#fff',
	gray: '#bbb',
	background: '#bbb',
};


// Define some interfaces
export interface StylingProps {
	color?: keyof (typeof theme)['colors'] | 'gray' | 'plain';
	inverted?: boolean;
	border?: boolean;
	link?: boolean;
	smaller?: boolean;
	smallest?: boolean;
	isLoading?: boolean;
	useBackgroundColorWithFocus?: boolean;
}

interface ButtonProps {
	type: 'button' | 'submit';
}


// Define the CSS separately, for reusability
export const styling = css<StylingProps>`
	appearance: none;
	border: 0;
	height: 2.25em;
	border-radius: 0.25rem;
	display: inline-flex;
	align-items: center;
	padding: 0 0.75em;
	white-space: nowrap;
	background: ${(props) =>
		props.color === 'plain' || props.color === 'gray'
			? genericColorLookup[props.color]
			: theme.colors[props.color || 'accent']};
	color: ${(props) => (props.color === 'plain' ? '#363636' : '#fff')} !important;
	font-size: 1em;
	font-family: inherit;
	font-weight: normal;
	transition: all 0.15s ease;
	position: relative;
	user-select: none;
	line-height: 1;
	text-decoration: none;
	box-shadow: ${(props) => props.theme.boxShadow ?? ''};
	
	${(props) =>
		props.border
			? `border: 1px solid ${
					props.color === 'plain' || props.color === 'gray'
						? genericColorLookup.gray
						: props.color === 'background'
						  ? darken(0.1, theme.colors.background)
						  : theme.colors[props.color || 'accent']
			  };`
			: ''}
	${(props) => (props.smaller ? 'height: 2.2em; padding: 0 0.75em; font-size: 0.9em;' : '')}
	${(props) => (props.smallest ? 'height: 1.7em; padding: 0 0.4em; font-size: 0.75em;' : '')}
	
	&:hover:not(:disabled):not([data-disabled]), &[data-hover]:not(:disabled):not([data-disabled]) {
		background: ${(props) =>
			darken(
				0.05,
				props.color === 'plain' || props.color === 'gray'
					? genericColorLookup[props.color]
					: theme.colors[props.color || 'accent']
			)};
		cursor: pointer;
	}
	
	&:active:not(:disabled):not([data-disabled]),
	&[data-active]:not(:disabled):not([data-disabled]) {
		background: ${(props) =>
			darken(
				0.1,
				props.color === 'plain' || props.color === 'gray'
					? genericColorLookup[props.color]
					: theme.colors[props.color || 'accent']
			)};
	}
	
	&:focus:not(:disabled):not([data-disabled]),
	&:focus-within:not(:disabled):not([data-disabled]),
	&[data-focus]:not(:disabled):not([data-disabled]) {
		outline: 0;
		box-shadow:
			${(props) => (props.theme.boxShadow ? `${props.theme.boxShadow},` : '')} 0 0 0 0.125em
				${(props) => (props.useBackgroundColorWithFocus ? theme.colors.background ?? '#fff' : '#fff')},
			0 0 0 0.25em
				${(props) =>
					props.color === 'plain' || props.color === 'gray'
						? transparentize(0.25, genericColorLookup.gray)
						: props.color === 'background'
						  ? transparentize(0.75, darken(0.5, theme.colors.background))
						  : transparentize(0.75, theme.colors[props.color || 'accent'])};
	}
	
	&:disabled,
	&[data-disabled] {
		opacity: 0.5;
		cursor: default;
		
		&:active {
			pointer-events: none;
		}
	}
	
	&::-moz-focus-inner {
		border: 0;
	}
	
	svg.svg-inline--fa:not(:last-child) {
		margin-right: 0.7em;
	}
	
	svg.svg-inline--fa:not(:first-child) {
		margin-left: 0.7em;
	}
	
	${(props) =>
		props.inverted
			? css`
					background: #fff;
					color: ${props.color === 'plain'
						? '#363636'
						: props.color === 'gray' || props.color === 'background'
						  ? darken(0.33, genericColorLookup[props.color])
						  : theme.colors[props.color || 'accent']} !important;
					
					&:hover:not(:disabled):not([data-disabled]),
					&[data-hover]:not(:disabled):not([data-disabled]) {
						background: ${props.color === 'plain' || props.color === 'gray'
							? setLightness(0.95, genericColorLookup.gray)
							: setLightness(0.97, theme.colors[props.color || 'accent'])};
					}
					
					&:active:not(:disabled):not([data-disabled]),
					&[data-active]:not(:disabled):not([data-disabled]) {
						background: ${props.color === 'plain' || props.color === 'gray'
							? setLightness(0.92, genericColorLookup.gray)
							: setLightness(0.94, theme.colors[props.color || 'accent'])};
					}
			  `
			: ''}
	
	${(props) =>
		props.link
			? css`
					background: transparent;
					text-decoration: underline;
			  `
			: ''}
	
	${(props) =>
		props.isLoading
			? css`
					color: transparent !important;
					pointer-events: none;
					
					&::after {
						border-color: transparent transparent
							${props.inverted
								? props.color === 'plain'
									? '#363636'
									: props.color === 'gray' || props.color === 'background'
									  ? darken(0.33, genericColorLookup[props.color])
									  : theme.colors[props.color || 'accent']
								: props.color === 'plain'
								  ? '#363636 #363636'
								  : '#fff #fff'} !important;
						position: absolute;
						left: calc(50% - 0.5em - 2px);
						top: calc(50% - 0.5em - 2px);
						animation: spinAround 0.5s linear infinite;
						border-radius: 290486px;
						border-style: solid;
						border-width: 2px;
						content: '';
						display: block;
						height: 1em;
						width: 1em;
					}
			  `
			: ''}
	
	@keyframes spinAround {
		from {
			transform: rotate(0deg);
		}
		
		to {
			transform: rotate(359deg);
		}
	}
`;


// A button that can be shown with a variety of appearances
const Button = styled(
	// eslint-disable-next-line react/display-name
	React.forwardRef<HTMLButtonElement, StylingProps & ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>>(
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		({ color, inverted, border, link, smaller, smallest, isLoading, useBackgroundColorWithFocus, ...props }, ref) => (
			<button {...props} ref={ref} />
		)
	)
)`
	${styling}
`;

export default Button;
