// Imports
import theme from '../../../theme';
import styled from 'styled-components/macro';
import { setLightness } from 'polished';


// Styled components
const Box = styled.div`
	width: 80%;
	max-width: 450px;
	margin: 0 auto;
	padding: 1.5rem;
	border-radius: 3px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #fff;
	
	a,
	span.is-link {
		color: ${theme.colors.link};
		cursor: pointer;
		text-decoration: none;
		
		&:hover {
			color: ${setLightness(0.29, theme.colors.primary)};
		}
	}
	
	@media (max-width: ${theme.breakpoints.mobile}px) {
		margin-left: 1rem;
		margin-right: 1rem;
		width: calc(100% - 2rem);
		max-width: calc(100% - 2rem);
	}
`;

export default Box;
