// Imports
import { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { APIFormOptions } from '..';
import { fieldContainerBottomMargin } from '../../../../../foundation/front-end/components/forms/field-container';
import commonActions from '../../../../../foundation/front-end/redux/actions';
import { useAppDispatch } from '../../../../../foundation/front-end/redux/hooks';
import thirdPartyIdentifiers from '../../../../../third-party-identifiers';
import ExpectedFormError from '../../../../../utils/errors/expected-form-error';


// Types
type RecaptchaOptions = NonNullable<APIFormOptions<unknown, unknown, unknown>['recaptcha']> & {
	appearsOnBackground?: boolean;
};


// Styled components
const ChallengeV2 = styled.div<{ v2ChallengeActive: boolean }>`
	> div {
		margin-bottom: ${fieldContainerBottomMargin};
	}
	
	${({ v2ChallengeActive }) =>
		!v2ChallengeActive
			? css`
					display: none;
			  `
			: ''}
`;

const RecaptchaLegalNotice = styled.div<Pick<RecaptchaOptions, 'appearsOnBackground'>>`
	text-align: center;
	margin-top: 0.75rem;
	font-size: 0.72rem;
	font-style: normal;
	font-weight: 300;
	line-height: 1;
	color: hsl(0, 0%, 21%);
	
	> a {
		cursor: pointer;
		text-decoration: underline;
		color: inherit;
		
		&:hover {
			text-decoration: none;
		}
	}
	
	${({ appearsOnBackground }) =>
		appearsOnBackground
			? css`
					color: #aaa;
					
					> a {
						color: #656565;
						text-decoration: none;
						
						&:hover {
							text-decoration: underline;
						}
					}
			  `
			: ''}
`;


// Define the accepted options
interface Options extends RecaptchaOptions {
	/** Unique ID to be used with reCAPTCHA widget */
	id: string;
	
	
	/** State setter for form loading state */
	setIsLoading: (value: React.SetStateAction<boolean>) => void;
}


// A hook that provides reCAPTCHA behavior, UI, and management
const useRecaptcha = ({ enabled, id, elementID, legalNotice, appearsOnBackground, setIsLoading }: Options) => {
	// Use state
	const [v2ChallengeActive, setV2ChallengeActive] = useState(false);
	
	
	// Use Redux functionality
	const dispatch = useAppDispatch();
	
	
	// Ref that holds the ID of the last reCAPTCHA v2 widget initialized by this form
	const v2WidgetID = useRef<number>();
	
	
	// Determine reCAPTCHA v3 site key
	const recaptchaSiteKeyV3 =
		thirdPartyIdentifiers.recaptchaSiteKeys.v3?.[process.env.REACT_APP__ENVIRONMENT === 'production' ? 'prd' : 'dev'];
	
	
	// Load reCAPTCHA if enabled
	useEffect(() => {
		if (!enabled) {
			return;
		}
		
		const script = document.createElement('script');
		script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKeyV3}`;
		
		script.async = true;
		document.head.appendChild(script);
		
		return () => {
			document.head.removeChild(script);
		};
	}, [recaptchaSiteKeyV3, enabled]);
	
	
	// Get reCAPTCHA headers
	const getRecaptchaHeaders = async () => {
		// If reCAPTCHA is not enabled, do no work
		if (!enabled) {
			return;
		}
		
		
		// Verify reCAPTCHA is available
		if (typeof window.grecaptcha !== 'object') {
			// Show page message
			dispatch(
				commonActions.showPageMessage({
					color: 'danger',
					title: 'Oh snap!',
					message: 'reCAPTCHA hasn’t loaded yet.',
				})
			);
			
			
			// Stop processing
			throw new ExpectedFormError();
		}
		
		
		// Call reCAPTCHA
		if (v2ChallengeActive) {
			return {
				'Recaptcha-Token': window.grecaptcha.getResponse(),
				'Recaptcha-Version': 'v2',
			};
		}
		
		return {
			'Recaptcha-Token': await window.grecaptcha.execute(recaptchaSiteKeyV3, {
				action: 'submit',
			}),
			'Recaptcha-Version': 'v3',
		};
	};
	
	
	// Keep track of component's mounted state
	const isMounted = useRef(true);
	
	useEffect(() => {
		isMounted.current = true;
		
		return () => {
			isMounted.current = false;
		};
	}, []);
	
	
	// Throw an error if any required legal document links are missing
	useEffect(() => {
		if (isMounted.current && enabled) {
			const privacyPolicy = document.querySelector('#recaptcha-privacy-policy');
			const tos = document.querySelector('#recaptcha-terms-of-service');
			
			if (!privacyPolicy || !tos) {
				throw new Error('Missing required reCAPTCHA legal notice');
			}
		}
	});
	
	
	// Initialize widget container ID
	const widgetContainerID = `${id}-recaptcha-challenge-v2`;
	
	
	// reCAPTCHA v2 handler
	const recaptchaV2 = () => {
		if (!v2ChallengeActive) {
			if (v2WidgetID.current === undefined) {
				const recaptchaSiteKeyV2 =
					thirdPartyIdentifiers.recaptchaSiteKeys.v2?.[
						process.env.REACT_APP__ENVIRONMENT === 'production' ? 'prd' : 'dev'
					];
				
				v2WidgetID.current = grecaptcha.render(elementID ?? widgetContainerID, {
					sitekey: recaptchaSiteKeyV2,
				});
			}
			
			setV2ChallengeActive(true);
		}
		
		if (isMounted.current) {
			setIsLoading(false);
		}
	};
	
	
	// Helper function that deactivates the reCAPTCHA challenge
	const deactivateRecaptchaChallenge = () => {
		if (v2ChallengeActive) {
			setV2ChallengeActive(false);
		}
	};
	
	
	// Helper function that resets the reCAPTCHA widget
	const resetRecaptchaWidget = () => {
		if (v2ChallengeActive) {
			window.grecaptcha.reset(v2WidgetID.current);
		}
	};
	
	
	// Build challenge JSX
	const challengeV2JSX = <ChallengeV2 id={widgetContainerID} v2ChallengeActive={v2ChallengeActive} />;
	
	
	// Build reCAPTCHA legal notice JSX
	let recaptchaLegalNoticeJSX: JSX.Element | null = null;
	
	if (enabled && !legalNotice?.disabled) {
		recaptchaLegalNoticeJSX = (
			<RecaptchaLegalNotice appearsOnBackground={appearsOnBackground ?? true}>
				This site is protected by reCAPTCHA and the
				<br />
				Google{' '}
				<a
					id='recaptcha-privacy-policy'
					href='https://policies.google.com/privacy'
					target='_blank'
					rel='noopener noreferrer'
				>
					Privacy Policy
				</a>{' '}
				and{' '}
				<a
					id='recaptcha-terms-of-service'
					href='https://policies.google.com/terms'
					target='_blank'
					rel='noopener noreferrer'
				>
					Terms of Service
				</a>{' '}
				apply.
			</RecaptchaLegalNotice>
		);
	}
	
	return {
		v2ChallengeActive,
		getRecaptchaHeaders,
		recaptchaV2,
		resetRecaptchaWidget,
		deactivateRecaptchaChallenge,
		challengeV2JSX,
		recaptchaLegalNoticeJSX,
	};
};

export default useRecaptcha;
