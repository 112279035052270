// Imports
import React from 'react';
import company from '../../../shareables/company';
import Box from '../../../shareables/foundation/simple-ui/components/box';
import GlobalStyle from '../../../shareables/foundation/simple-ui/components/global-style';
import { Content } from '../../app';
import Heading from '../heading';


// Define the accepted props
interface Props extends React.PropsWithChildren {
	storybookError?: true;
}


// Component class
//  -> There are no Hooks for error boundary functions yet (https://reactjs.org/docs/error-boundaries.html)
class ErrorBoundary extends React.Component<Props, { hasError: boolean }> {
	constructor(props: Props) {
		super(props);
		this.state = { hasError: props.storybookError ?? false };
	}
	
	static getDerivedStateFromError() {
		return { hasError: true };
	}
	
	render() {
		if (this.state.hasError) {
			return (
				<React.StrictMode>
					<GlobalStyle />
					
					<Heading />
					
					<Content>
						<Box as='main'>
							<strong>Oh snap!</strong> An unexpected issue occurred. If this persists, please{' '}
							<a href={`https://help.${company.productionDomain ?? ''}/`} target='_blank' rel='noopener noreferrer'>
								contact us
							</a>
							.
						</Box>
					</Content>
				</React.StrictMode>
			);
		}
		
		return this.props.children;
	}
}


// Export
export default ErrorBoundary;
