// Imports
import { combineReducers } from 'redux';
import apiReducer from '../shareables/integrations/api/redux/reducer';
import commonReducer from '../shareables/foundation/front-end/redux/reducer';


// The root reducer
export default combineReducers({
	api: apiReducer,
	common: commonReducer,
});
