// Imports
import theme from '../../../theme';
import { createGlobalStyle } from 'styled-components/macro';


// Global style
const GlobalStyle = createGlobalStyle`
	body {
		margin: 0;
		padding: 0;
		font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		text-rendering: optimizeLegibility;
		text-size-adjust: 100%;
		font-variant-numeric: lining-nums;
		font-feature-settings:"lnum" 1;
		min-height: 100%;
		background: ${theme.colors.background};
		color: #4a4a4a;
		line-height: 1.5;
	}
	
	blockquote, body, dd, dl, dt, fieldset, figure, h1, h2, h3, h4, h5, h6, hr, html, iframe, legend, li, ol, p, pre, textarea, ul {
		margin: 0;
		padding: 0;
	}
	
	*:not(.svg-inline--fa) {
		box-sizing: border-box;
	}
	
	.tippy-content {
		text-align: center;
	}
	
	a,
	span,
	small,
	svg {
		&.is-green {
			color: ${theme.colors.success} !important;
		}
		
		&.is-yellow {
			color: ${theme.colors.yellow} !important;
		}
		
		&.is-red {
			color: ${theme.colors.danger} !important;
		}
		
		&.is-gray {
			color: #7a7a7a !important;
		}
	}
	
	fieldset {
		border: 0;
	}
`;

export default GlobalStyle;
