// Imports
import get from 'lodash/get';
import isEqualWith from 'lodash/isEqualWith';
import createPathParts from './create-path-parts';
import { ParametersOrFiles } from '../../../redux/types';
import isObject from 'lodash/isObject';


// Helper function that implements special handling for comparing files and file lists
const compareFiles = (fileA: File, fileB: File) => {
	const valuesToCompare: (keyof File)[] = ['lastModified', 'name', 'size', 'type'];
	
	return valuesToCompare.every((key) => fileA[key] === fileB[key]);
};

const comparator = (objectA: unknown, objectB: unknown) => {
	// Check if either object is a File instance
	const aIsFile = objectA instanceof File;
	const bIsFile = objectB instanceof File;
	
	
	// They are not the same prototype, so we can return false safely
	if (aIsFile !== bIsFile) {
		return false;
	}
	
	
	// Compare files
	if (aIsFile && bIsFile) {
		return compareFiles(objectA, objectB);
	}
	
	
	// Check if either object is a FileList instace
	const aIsFileList = objectA instanceof FileList;
	const bIsFileList = objectB instanceof FileList;
	
	
	// They are not the same prototype, so we can return false safely
	if (aIsFileList !== bIsFileList) {
		return false;
	}
	
	
	// Compare FileList instances by comparing their individual files
	if (aIsFileList && bIsFileList) {
		if (objectA.length !== objectB.length) {
			return false;
		}
		
		for (let i = 0; i < objectA.length; i += 1) {
			const isEqual = compareFiles(objectA[i], objectB[i]);
			
			if (!isEqual) {
				return false;
			}
		}
		
		return true;
	}
	
	
	// By returning undefined here we delegate comparisons back to `isEqual` default behavior for all other types
	return undefined;
};


// Adjust a path that was produced from pruned parameters to point to the right place in the unpruned version
function determineUnprunedPath(
	pathToModify: string,
	unprunedParameters: ParametersOrFiles,
	prune: (object: ParametersOrFiles) => {
		[key: string]: unknown;
	}
): string;
function determineUnprunedPath(
	pathToModify: string,
	unprunedParameters: ParametersOrFiles,
	prune: (object: ParametersOrFiles) => {
		[key: string]: unknown;
	},
	pathParts: string[],
	originalPath: string,
	originalParts: string[]
): string;
function determineUnprunedPath(
	pathToModify: string,
	unprunedParameters: ParametersOrFiles,
	prune: (object: ParametersOrFiles) => {
		[key: string]: unknown;
	},
	pathParts?: string[],
	originalPath?: string,
	originalParts?: string[]
): string {
	// If the path is not located in an array, return the path
	if (!pathParts && createPathParts(pathToModify) === null) {
		return pathToModify;
	}
	
	
	// Initialize recursion
	if (!pathParts) {
		const [firstMatch, ...allOtherMatches] = createPathParts(pathToModify) ?? [];
		
		return determineUnprunedPath(firstMatch, unprunedParameters, prune, allOtherMatches, firstMatch, allOtherMatches);
	}
	
	
	// Throw an error if we reach this point
	if (!pathParts || !originalParts || !originalPath) {
		throw new Error(
			`This state should not be reached, parameters: ${JSON.stringify({
				pathToModify,
				unprunedParameters,
				pathParts,
				originalPath,
				originalParts,
			})}`
		);
	}
	
	
	// Grab the piece of the path we want
	let workingPath = pathToModify.slice(0, pathToModify.lastIndexOf(']') + 1);
	
	
	// Grab the value the original path was pointing at
	const prunedValue = get(prune(unprunedParameters), originalPath.slice(0, originalPath.lastIndexOf(']') + 1));
	
	
	// If the pruned value exists, check for duplicates
	if (prunedValue !== undefined) {
		// Take the index number from the path for modification
		let indexToModify = Number(pathToModify.slice(pathToModify.lastIndexOf('[') + 1, pathToModify.lastIndexOf(']')));
		
		
		// Determine the number of duplicate values that occur before we reach the desired element
		let numberOfDuplicatesBeforeMatch = 0;
		const arrayOrFileList = get(prune(unprunedParameters), originalPath.slice(0, originalPath.lastIndexOf('[')));
		
		if (!(arrayOrFileList instanceof Array) && !(arrayOrFileList instanceof FileList)) {
			throw new Error('Lodash `get()` produced an unexpected value');
		}
		
		for (let i = 0; i < arrayOrFileList.length; i += 1) {
			if (isEqualWith(prunedValue, arrayOrFileList[i], comparator) && i < indexToModify) {
				numberOfDuplicatesBeforeMatch += 1;
			}
		}
		
		
		// Update the index
		let valueForComparison = get(unprunedParameters, workingPath) as unknown;
		
		while (
			!isEqualWith(
				Array.isArray(valueForComparison) || isObject(valueForComparison)
					? prune(valueForComparison)
					: valueForComparison,
				prunedValue,
				comparator
			) ||
			numberOfDuplicatesBeforeMatch
		) {
			// If it is a duplicate value, decrement the number before the matching value
			if (
				isEqualWith(
					Array.isArray(valueForComparison) || isObject(valueForComparison)
						? prune(valueForComparison)
						: valueForComparison,
					prunedValue,
					comparator
				)
			) {
				numberOfDuplicatesBeforeMatch -= 1;
			}
			
			
			// Increase index by one to check the next element in the array
			workingPath = `${workingPath.slice(
				0,
				workingPath.lastIndexOf('[') + 1
			)}${(indexToModify += 1)}${workingPath.slice(workingPath.lastIndexOf(']'))}`;
			
			valueForComparison = get(unprunedParameters, workingPath);
		}
	}
	
	
	// Append object properties back onto the path
	if (pathToModify.lastIndexOf(']') !== pathToModify.length - 1) {
		workingPath = `${workingPath}${pathToModify.slice(pathToModify.lastIndexOf(']') + 1)}`;
	}
	
	
	// If there are still path parts to verify, recurse again
	if (pathParts.length > 0) {
		const [nextPartToModify, ...remainingPartsToModify] = pathParts;
		const [nextOriginalPart, ...remainingOriginalParts] = originalParts;
		
		return determineUnprunedPath(
			`${workingPath}${nextPartToModify}`,
			unprunedParameters,
			prune,
			remainingPartsToModify,
			`${originalPath}${nextOriginalPart}`,
			remainingOriginalParts
		);
	}
	
	
	// Return the now-unpruned path
	return workingPath;
}

export default determineUnprunedPath;
