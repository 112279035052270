// Imports
import { faArrowsRotate } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import Select from '../../../shareables/foundation/front-end/components/forms/fields/select';
import commonActions from '../../../shareables/foundation/front-end/redux/actions';
import { useAppDispatch } from '../../../shareables/foundation/front-end/redux/hooks';
import Box from '../../../shareables/foundation/simple-ui/components/box';
import APIForm, { APIFormOptions } from '../../../shareables/integrations/api/components/form';
import { GetWebsitesResponse } from '../../../shareables/types/api/main/app/websites/get';
import Subheading from '../../resources/subheading';


// Styled components
const Heading = styled(Subheading)`
	margin-bottom: 0.625rem;
`;

const Explanation = styled.p`
	margin-bottom: 1.25rem;
	line-height: 1.3;
	
	> a {
		color: inherit;
		text-decoration: underline;
		cursor: pointer;
		
		&:hover {
			color: inherit;
			text-decoration: none;
		}
	}
`;

const LoadingWrapper = styled.div`
	text-align: center;
	font-style: italic;
	margin: auto;
`;


// Define the accepted props
interface Props {
	websites: GetWebsitesResponse | null;
}


// Function component
const TOARequest: React.FC<Props> = ({ websites }) => {
	// Use Redux functionality
	const dispatch = useAppDispatch();
	
	
	// Use ref
	const websiteIDGetter = useRef<() => string | number>();
	
	
	// Use React Router functionality
	const navigate = useNavigate();
	
	
	// Handle form completion
	const handleFormCompletion = () => {
		const website = websites?.find((website) => website.id === Number(websiteIDGetter.current?.()));
		
		if (!website) {
			throw new Error('This should not be possible');
		}
		
		dispatch(
			commonActions.showPageMessage({
				title: 'Success!',
				message: `A transfer of administration process for “${website.communityName}” has been initiated. This process can take a couple of weeks to complete.`,
				color: 'success',
			})
		);
		
		navigate('/');
	};
	
	
	// Filter out websites where the user is already a full admin
	const filteredWebsites = websites?.filter((website) => !website.privileges.includes('*'));
	
	
	// Build form options
	const formOptions: APIFormOptions<undefined, undefined, undefined> = {
		action: 'REQUEST',
		uri: '/website/[websiteID]/toa',
		submitButton: {
			text: 'Request',
			disabled: filteredWebsites?.length === 0,
		},
		appearsOnBackground: false,
	};
	
	return filteredWebsites ? (
		<Box>
			<Heading>Request a transfer of administration</Heading>
			
			<APIForm options={formOptions} onCompletion={handleFormCompletion}>
				<Explanation>
					Use this form to initiate a transfer of administration process, as outlined in our{' '}
					<a
						rel='noopener noreferrer'
						target='_blank'
						href={`https://www.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}/terms-of-service`}
					>
						terms of service
					</a>
					. This process is designed to help people recover management of a community website when the current
					administrator is unresponsive, unknown, or no longer involved with the community. The process is fully
					automated and can take a couple of weeks to complete.
				</Explanation>
				
				<Select
					name='websiteID'
					label='Community website'
					placeholder={filteredWebsites.length === 0 ? 'No eligible community websites…' : 'Please select…'}
					disabled={filteredWebsites.length === 0}
					bindGetter={(getter) => (websiteIDGetter.current = getter)}
					required
				>
					{filteredWebsites.map((website) => (
						<option key={website.id} value={website.id}>
							{website.communityName}
						</option>
					))}
				</Select>
			</APIForm>
		</Box>
	) : (
		<LoadingWrapper>
			<FontAwesomeIcon icon={faArrowsRotate} spin fixedWidth /> Loading…
		</LoadingWrapper>
	);
};

export default TOARequest;
