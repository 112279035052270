// Imports
import { useState, useRef } from 'react';
import MicroModal from 'micromodal';
import uniqueId from 'lodash/uniqueId';
import WarningModal from '../../../../../shareables/foundation/front-end/components/resources/warning-modal';


// Define the accepted options
interface WarningModalOptionsBase {
	/** Whether to enable the warning modal. */
	enabled: boolean;
	
	
	/** The function to run on modal confirmation. */
	onConfirmation: () => void;
	
	
	/** The function to run on modal cancellation. */
	onCancel: () => void;
	
	
	/** The title of the warning. Defaults to `'Are you sure?'`. */
	question?: string;
	
	
	/** The message of the warning. Provides details about the action the user is taking. */
	message?: JSX.Element | string;
	
	
	/** The text for the confirmation button. Defaults to `'Yes'`. */
	button?: string;
}

interface EnabledWarningModalOptions extends WarningModalOptionsBase {
	/** Whether to enable the warning modal. */
	enabled: true;
	
	
	/** The message of the warning. Provides details about the action the user is taking. */
	message: JSX.Element | string;
}

export type WarningModalOptions = EnabledWarningModalOptions | WarningModalOptionsBase;


// A hook that handles management of the warning modal
const useWarningModal = ({ enabled, onConfirmation, onCancel, question, message, button }: WarningModalOptions) => {
	// Use state
	const [visible, setVisible] = useState(false);
	
	
	// Use ref
	const uniqueIdentifier = useRef(uniqueId());
	
	
	// Function that opens the modal, returns whether the modal was opened
	const open = () => {
		if (!enabled || visible) {
			return false;
		}
		
		MicroModal.show(`warning-modal-${uniqueIdentifier.current}`, {
			awaitCloseAnimation: true,
			onShow: () => {
				setVisible(true);
			},
			onClose: () => {
				setVisible(false);
			},
		});
		
		return true;
	};
	
	
	// Function that closes the modal
	const close = () => {
		if (!enabled || !visible) {
			return;
		}
		
		MicroModal.close(`warning-modal-${uniqueIdentifier.current}`);
	};
	
	
	// Handle confirmation
	const handleConfirmation = () => {
		// Close the modal
		close();
		
		
		// Call handler
		onConfirmation();
	};
	
	
	// Return
	return {
		component: enabled ? (
			<WarningModal
				uniqueIdentifier={uniqueIdentifier.current}
				question={question ?? 'Are you sure?'}
				message={message ?? ''}
				button={button ?? 'Yes'}
				onSubmit={handleConfirmation}
				onCancel={onCancel}
			/>
		) : null,
		open,
	};
};

export default useWarningModal;
