// Imports
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useMatches } from 'react-router-dom';
import Header from '../../../shareables/foundation/simple-ui/components/header';
import Logo from '../../../shareables/front-end/svg-logo';


// Function component
const Heading: React.FC = () => {
	// Use React Router functionality
	const matches = useMatches();
	
	
	// Custom typeguard
	const isRouteTitle = (handle: object): handle is { title: string } =>
		'title' in handle && typeof (handle as { title: string }).title === 'string';
	
	
	// Get title
	let title = '';
	
	for (let i = matches.length - 1; i > -1; i -= 1) {
		const { handle } = matches[i];
		
		if (typeof handle === 'object' && handle) {
			if (isRouteTitle(handle)) {
				title = handle.title;
			}
			
			break;
		}
	}
	
	if (!title) {
		throw new Error('Page title not provided on any route');
	}
	
	
	// Return JSX
	return (
		<React.Fragment>
			<Helmet title={title} />
			<Header logoElement={<Logo />} title={title} />
		</React.Fragment>
	);
};

export default Heading;
