// Imports
import styled from 'styled-components/macro';


// Styled components
const Subheading = styled.h2`
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 1;
`;

export default Subheading;
