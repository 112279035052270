// Imports
import { ParametersOrFiles } from '../../../../../shareables/integrations/api/redux/types';
import helperFunctions from '../../../../../shareables/foundation/front-end/utils/helper-functions';


/** Prunes empty strings, arrays, and objects from parameters and files. */
export default function pruneParametersAndFiles(
	originalParameters: ParametersOrFiles,
	originalFiles: ParametersOrFiles
) {
	return {
		parameters: helperFunctions.pruneEmptyObjectsAndArrays(helperFunctions.pruneEmptyStrings(originalParameters)),
		files: helperFunctions.pruneEmptyObjectsAndArrays(originalFiles),
	};
}
