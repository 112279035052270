// Imports
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import { setLightness, setSaturation } from 'polished';
import theme from '../../../../../shareables/theme';
import Button from '../../../../../shareables/foundation/front-end/components/buttons/standard';
import ButtonGroup from '../../../../../shareables/foundation/front-end/components/buttons/group';
import helperFunctions from '../../../../../shareables/foundation/front-end/utils/helper-functions';
import MicroModal from 'micromodal';
import * as Sentry from '@sentry/browser';
import { useAppDispatch } from '../../redux/hooks';
import commonActions from '../../redux/actions';
import parseMarkdown from '../../../../utils/markdown/parse';


// Styled elements
const Container = styled.div`
	display: none;
	
	&.is-open {
		display: block;
	}
`;

const Fade = styled.div`
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	position: fixed;
	background: rgba(0, 0, 0, 0.4);
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	
	/* prettier-ignore */
	${Container}[aria-hidden="false"] & {
		animation: fadeIn 0.3s ease-in-out;
	}
	
	/* prettier-ignore */
	${Container}[aria-hidden="true"] & {
		animation: fadeOut 0.15s ease-in-out;
	}
	
	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		
		to {
			opacity: 1;
		}
	}
	
	@keyframes fadeOut {
		from {
			opacity: 1;
		}
		
		to {
			opacity: 0;
		}
	}
`;

const Dialog = styled.div`
	max-width: 90%;
	background: #fff;
	z-index: 101;
	border-radius: 0.4rem;
	padding: 1.5rem;
	text-align: center;
	z-index: 101;
	will-change: transform;
	
	/* prettier-ignore */
	${Container}[aria-hidden="false"] & {
		animation: appear 0.3s ease-in-out;
	}
	
	/* prettier-ignore */
	${Container}[aria-hidden="true"] & {
		animation: disappear 0.15s ease-in-out;
	}
	
	@keyframes appear {
		0% {
			transform: scale(0.7);
		}
		
		45% {
			transform: scale(1.05);
		}
		
		80% {
			transform: scale(0.95);
		}
		
		100% {
			transform: scale(1);
		}
	}
	
	@keyframes disappear {
		from {
			transform: scale(1);
		}
		
		to {
			transform: scale(0.5);
		}
	}
`;

const ClickAnywhereToClose = styled.div`
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	position: fixed;
	z-index: 100;
`;

const Icon = styled.h2`
	display: flex;
	position: relative;
	box-sizing: content-box;
	justify-content: center;
	width: 4em;
	height: 4em;
	margin: 0 auto 1.25em;
	zoom: normal;
	border: 0.2em solid transparent;
	border-radius: 50%;
	font-family: inherit;
	line-height: 4em;
	cursor: default;
	user-select: none;
	border-color: ${setSaturation(0.9, setLightness(0.7, theme.colors.danger))};
	color: ${setSaturation(0.8, setLightness(0.45, theme.colors.danger))};
	
	&::before {
		content: '!';
		display: flex;
		align-items: center;
		height: 92%;
		font-size: 3.25em;
		font-weight: 400;
	}
	
	@media (max-height: 500px) {
		display: none;
	}
`;

const Question = styled.h3`
	max-width: 500px;
	margin: 0 0 1rem;
	padding: 0;
	line-height: 1;
	font-size: 1.75rem;
	font-weight: 600;
	color: #222;
`;

const Message = styled.p`
	max-width: 500px;
	margin: 0 0 1.5rem;
	padding: 0;
	color: #555;
	font-weight: 300;
	font-size: 1.1em;
`;

const StyledButtonGroup = styled(ButtonGroup)`
	max-width: 500px;
	justify-content: center;
	font-size: 1.25em;
	
	button {
		white-space: inherit;
		height: auto;
		padding-top: 0.625em;
		padding-bottom: 0.625em;
	}
`;

const ConfirmationButton = styled(Button)`
	max-width: 100%;
	overflow: hidden;
	overflow-wrap: anywhere;
	hyphens: auto;
`;


// Define the accepted props
interface Props {
	uniqueIdentifier: string;
	question: string;
	message: JSX.Element | string;
	button: string;
	onSubmit: () => void | Promise<void>;
	onCancel?: () => void;
}


// Function component
const WarningModal: React.FC<Props> = ({ uniqueIdentifier, question, message, button, onSubmit, onCancel }) => {
	// Use state
	const [isSubmitting, setIsSubmitting] = useState(false);
	
	
	// Use Redux functionality
	const dispatch = useAppDispatch();
	
	
	// Used to detect mount status
	const isMounted = useRef(true);
	
	useEffect(() => {
		isMounted.current = true;
		
		return () => {
			isMounted.current = false;
		};
	});
	
	
	// Get body element (return null if it's missing for some reason)
	const bodyElement = document.querySelector('body');
	
	if (!bodyElement) {
		return null;
	}
	
	
	// Handle cancel
	const handleCancel = () => {
		MicroModal.close(`warning-modal-${uniqueIdentifier}`);
		
		onCancel?.();
	};
	
	
	// Handle submit
	const handleSubmit = () => {
		// Call submit handler
		const response = onSubmit();
		
		
		// If we received a promise in response, set status to submitting
		if (response instanceof Promise) {
			setIsSubmitting(true);
			
			response
				.then(() => {
					if (isMounted.current) {
						setIsSubmitting(false);
					}
				})
				.catch((error: unknown) => {
					// Report to Sentry
					Sentry.captureException(error);
					
					
					// Show page error
					dispatch(
						commonActions.showPageMessage({
							color: 'danger',
							title: 'Oh snap!',
							message:
								error instanceof Error && error.message ? (
									<span dangerouslySetInnerHTML={{ __html: parseMarkdown(error.message) }} />
								) : (
									'There was an unexpected issue.'
								),
						})
					);
					
					
					// Reset back to not submitting
					if (isMounted.current) {
						setIsSubmitting(false);
					}
					
					
					// Treat as a cancellation
					handleCancel();
				});
		}
	};
	
	
	// Return JSX
	return ReactDOM.createPortal(
		<Container id={`warning-modal-${uniqueIdentifier}`} aria-hidden='true'>
			<Fade tabIndex={-1}>
				<Dialog role='dialog' aria-modal='true' aria-labelledby={`warning-modal-question${uniqueIdentifier}`}>
					<Icon />
					
					<header>
						<Question id={`warning-modal-question${uniqueIdentifier}`}>{question}</Question>
					</header>
					
					<Message>{message}</Message>
					
					<StyledButtonGroup>
						<ConfirmationButton
							type='submit'
							isLoading={isSubmitting}
							disabled={isSubmitting}
							color='danger'
							value='confirmed'
							{...helperFunctions.buttonize(handleSubmit)}
						>
							{button}
						</ConfirmationButton>
						
						<Button type='button' color='gray' value='canceled' onClick={handleCancel} disabled={isSubmitting}>
							Cancel
						</Button>
					</StyledButtonGroup>
				</Dialog>
				
				<ClickAnywhereToClose onClick={handleCancel} />
			</Fade>
		</Container>,
		bodyElement
	);
};

export default WarningModal;
