// Imports
import React, { AnchorHTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import { StylingProps, styling } from './standard';


// An anchor tag that's styled like a button
const AnchorButton = styled(
	// eslint-disable-next-line react/display-name
	React.forwardRef<HTMLAnchorElement, Omit<StylingProps & AnchorHTMLAttributes<HTMLAnchorElement>, 'type'>>(
		({ color, inverted, border, link, smaller, smallest, isLoading, useBackgroundColorWithFocus, ...props }, ref) => (
			// eslint-disable-next-line @typescript-eslint/no-unused-vars, jsx-a11y/anchor-has-content
			<a {...props} ref={ref} />
		)
	)
)`
	${styling}
`;

export default AnchorButton;
