// Imports
import styled from 'styled-components/macro';


// Define the accepted props
interface Props {
	disableWrapping?: true;
}


// A group of buttons
const ButtonGroup = styled.div<Props>`
	display: flex;
	justify-content: flex-start;
	flex-wrap: ${(props) => (props.disableWrapping ? 'nowrap' : 'wrap')};
	margin-bottom: -0.75rem;
	
	> * {
		margin-bottom: 0.75rem !important;
	}
	
	> *:not(:last-child) {
		margin-right: 0.75rem;
	}
`;

export default ButtonGroup;
