// Imports
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './components/app';
import ErrorBoundary from './components/resources/error-boundary';
import store from './shareables/foundation/front-end/redux/store';
import setUp from './shareables/foundation/front-end/utils/set-up';


// Set up
setUp();


// Disable Plausible if not in production
if (process.env.REACT_APP__ENVIRONMENT !== 'production') {
	localStorage.setItem('plausible_ignore', 'true');
}


// Create root
const container = document.getElementById('root');

if (!container) {
	throw new Error('Root container should exist');
}

const root = ReactDOM.createRoot(container);


// Mount & render
root.render(
	<ErrorBoundary>
		<Provider store={store}>
			<App />
		</Provider>
	</ErrorBoundary>
);
