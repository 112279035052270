// Imports
import React, { useState, useRef, useEffect, useCallback } from 'react';
import APIConnectedFormContext, { ContextDetails } from '../../../../contexts/api-connected-form';
import { useAppDispatch } from '../../../../foundation/front-end/redux/hooks';
import set from 'lodash/set';
import get from 'lodash/get';
import apiActions from '../../redux/actions';
import commonActions from '../../../../foundation/front-end/redux/actions';
import * as Sentry from '@sentry/browser';
import Tippy from '@tippyjs/react';
import tippyProps from '../../../../foundation/front-end/utils/tippy-props';
import Button from '../../../../foundation/front-end/components/buttons/standard';
import ButtonGroup from '../../../../foundation/front-end/components/buttons/group';
import { ParametersOrFiles, APICallWithoutAutoErrorHandling } from '../../redux/types';
import determineUnprunedPath from './utils/determine-unpruned-path';
import omit from 'lodash/omit';
import styled, { ThemeProvider } from 'styled-components/macro';
import { fieldContainerBottomMargin } from '../../../../foundation/front-end/components/forms/field-container';
import { unstable_usePrompt as usePrompt } from 'react-router-dom';
import useWarningModal, { WarningModalOptions } from '../utils/use-warning-modal';
import pruneParametersAndFiles from '../utils/prune-parameters-and-files';
import ExpectedFormError from '../../../../utils/errors/expected-form-error';
import { z } from 'zod';
import merge from 'lodash/merge';
import helperFunctions from '../../../../foundation/front-end/utils/helper-functions';
import parseMarkdown from '../../../../utils/markdown/parse';
import GlobalAPIFormStyle from './global-style';
import useRecaptcha from './utils/use-recaptcha';


// Styled components
const Wrapper = styled.form`
	&:not(:last-child) {
		margin-bottom: ${fieldContainerBottomMargin};
	}
`;


// Define some foundational interfaces
interface ErrorHandler {
	/** An object of key-value pairs that should be matched for this handler to be used. All key-value pairs must be matched exactly. */
	match: Partial<UnsuccessfulAPIResponse>;
	
	
	/** The function that will be called when a response is matched. The response JSON is passed as the first parameter. */
	handler: (json: UnsuccessfulAPIResponse) => void;
}


// Define what the options structure looks like
export interface APIFormOptions<Parameters, Files, Response> {
	/** The action portion of the API endpoint (all caps). */
	action: string;
	
	
	/** The URI portion of the API endpoint, with a leading forward slash (`/`). The URI can contain values that are automatically replaced with values from its own fields. To use this feature, simply wrap the field’s name with brackets (`[]`), like `/website/[userID]/pages`. */
	uri: string;
	
	
	/** Options for customizing the submit button. */
	submitButton: {
		/** The content of the submit button. */
		text: string | React.ReactElement;
		
		
		/** A tooltip to show when someone hovers over the button. */
		tooltip?: string;
		
		
		/** The ARIA label, which is required if the button doesn’t have discernible text. */
		ariaLabel?: string;
		
		
		/** Whether to disable the submit button. Defaults to false. */
		disabled?: boolean;
		
		
		/** The color to use for the submit button. */
		color?: 'danger' | 'primary' | 'plain' | 'yellow';
		
		
		/** The size of the submit button, if not normal size. */
		size?: 'smaller' | 'smallest';
	};
	
	
	/** Options for customizing the cancel button. */
	cancelButton?: {
		/** The content of the cancel button. Defaults to `'Cancel'`. */
		text?: string | React.ReactElement;
	};
	
	
	/** Options for customizing navigation prompt behavior. */
	navigationPrompt?: {
		/** Whether to disable the prompt when navigating away from a dirty form. Defaults to `false`. */
		disabled?: boolean;
		
		
		/** The message of the prompt. Defaults to `'Changes were made to this form, are you sure you want to navigate away from this page?'`. */
		message?: string;
	};
	
	
	/** Options for enabling and customizing reCAPTCHA behavior. */
	recaptcha?: {
		/** Whether to enable reCAPTCHA. Defaults to `false`. */
		enabled?: boolean;
		
		
		/** If a reCAPTCHA v2 challenge needs to be shown, the ID of the element to display the challenge within. Defaults to an element just above the submit button. */
		elementID?: string;
		
		
		/** Options for the required reCAPTCHA legal notice. */
		legalNotice?: {
			/** Whether to disable the standard reCAPTCHA legal notice. Defaults to `false`. When set to `true`, a legal notice must be displayed complying with these requirements: https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed. An error will be thrown unless links with the following IDs are somewhere on the page: `recaptcha-privacy-policy`, `recaptcha-terms-of-service`. */
			disabled?: boolean;
		};
	};
	
	
	/** If provided, a warning will be shown before submitting, asking the user to confirm their action. */
	warning?: Omit<WarningModalOptions, 'enabled' | 'onConfirmation' | 'onCancel'>;
	
	
	/** An array of JSX to add after the submit and cancel buttons. */
	extraControls?: React.ReactElement[];
	
	
	/** Extra parameters to pass to the API in addition to the ones gathered from the form’s fields. */
	extraParameters?: Partial<Parameters>;
	
	
	/** Extra files to pass to the API in addition to the ones gathered from the form’s fields. */
	extraFiles?: Partial<Files>;
	
	
	/** An array of handler objects. */
	errorHandlers?: ErrorHandler[];
	
	
	/** Whether filled-out values should persist via the browser’s history API. Defaults to `false`. */
	recoverState?: boolean;
	
	
	/** Any of the call function’s parameters that you want to override. */
	customCallSettings?: Partial<APICallWithoutAutoErrorHandling<Parameters, Files, Response>>;
	
	
	/** Whether the form is rendered directly against the background of the app. Affects styling for any elements within the form whose styles interact with the background. Defaults to `true`. */
	appearsOnBackground?: boolean;
}


// Define the accepted props
interface Props<Parameters, Files, Response> {
	/** An object that governs how the form behaves. */
	options: APIFormOptions<Parameters, Files, Response>;
	
	
	/** A function that handles successful submissions. The function is passed the parsed JSON as the first parameter and the original parameters as the second parameter. If the function returns a promise, the connected form will wait for that promise to resolve before resetting the “submitting” state back to `false`. */
	onCompletion?: (json: SuccessfulAPIResponse<Response>, parameters: Parameters) => void | Promise<unknown>;
	
	
	/** If a function is passed, a cancel button will be added to the form and this function will be called when that button is clicked. */
	onCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	
	
	/** A function that handles a change in value to any of the fields. The function is passed the latest values and files as its two parameters. */
	onChange?: (values: Partial<Parameters>, files: Partial<Files>) => void;
	
	
	/** Class names to give to the form element. */
	className?: string;
	
	
	/** A standard React ref for the form element. */
	ref?: React.MutableRefObject<HTMLFormElement | null> | ((instance: HTMLFormElement | null) => void) | null;
}


// Function component
const APIForm = React.forwardRef(function APIFormInner<Parameters = undefined, Files = undefined, Response = undefined>(
	{
		options,
		onCompletion,
		onCancel,
		onChange,
		className,
		children,
	}: React.PropsWithChildren<Props<Parameters, Files, Response>>,
	ref: React.ForwardedRef<HTMLFormElement>
) {
	// Use state
	const [isLoading, setIsLoading] = useState(false);
	const [errorField, setErrorField] = useState<string | null>(null);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [warnBeforeNavigation, setWarnBeforeNavigation] = useState(false);
	
	
	// Used to detect mount status
	const isMounted = useRef(true);
	
	useEffect(() => {
		isMounted.current = true;
		
		return () => {
			isMounted.current = false;
		};
	});
	
	
	// Define and use a ref for storing value/file getters
	interface ValueGetters {
		[index: string]: () => string | number | boolean;
	}
	
	interface FileGetters {
		[index: string]: () => File | FileList | null;
	}
	
	interface TokenGetters {
		[index: string]: () => Promise<string>;
	}
	
	const valueGetters = useRef<ValueGetters>({});
	const fileGetters = useRef<FileGetters>({});
	const tokenGetters = useRef<TokenGetters>({});
	const errorContainers = useRef<Set<string>>(new Set<string>());
	
	
	// Reusable function to get values and files
	const getValuesAndFiles = () => {
		// Initialize
		const values: Partial<Parameters> = {};
		const files: Partial<Files> = {};
		
		
		// Loop over getters
		for (const name of Object.keys(valueGetters.current)) {
			set(values, name, valueGetters.current[name]());
		}
		
		for (const name of Object.keys(fileGetters.current)) {
			set(files, name, fileGetters.current[name]());
		}
		
		
		// Return
		return {
			values,
			files,
		};
	};
	
	
	// Function that sets the error field and message
	const setError = useCallback((field: string, message: string) => {
		if (
			Object.prototype.hasOwnProperty.call(valueGetters.current, field) ||
			Object.prototype.hasOwnProperty.call(fileGetters.current, field) ||
			Object.prototype.hasOwnProperty.call(tokenGetters.current, field) ||
			errorContainers.current.has(field)
		) {
			setErrorField(field);
			setErrorMessage(message);
			
			return;
		}
		
		throw new Error('Set error was called with a nonexistent field');
	}, []);
	
	
	// Function that clears error state
	const clearErrors = useCallback(
		(fieldToClear?: string) => {
			if (errorField === fieldToClear || fieldToClear === undefined) {
				setErrorField(null);
				setErrorMessage(null);
			}
		},
		[errorField]
	);
	
	
	// Add a value getter
	const addValueGetter: NonNullable<ContextDetails>['addValueGetter'] = useCallback((name, getter) => {
		if (valueGetters.current[name] !== undefined || tokenGetters.current[name] !== undefined) {
			throw new Error(`Field names must be unique: ${name} already exists`);
		}
		
		valueGetters.current[name] = getter;
	}, []);
	
	
	// Remove a value getter and associated errors
	const removeValueGetter: NonNullable<ContextDetails>['removeValueGetter'] = useCallback(
		(name) => {
			const { [name]: remove, ...everythingElse } = valueGetters.current;
			
			valueGetters.current = everythingElse;
			
			clearErrors(name);
		},
		[clearErrors]
	);
	
	
	// Add a file getter
	const addFileGetter: NonNullable<ContextDetails>['addFileGetter'] = useCallback((name, getter) => {
		if (fileGetters.current[name] !== undefined) {
			throw new Error(`Field names must be unique: ${name} already exists`);
		}
		
		fileGetters.current[name] = getter;
	}, []);
	
	
	// Remove a file getter and associated errors
	const removeFileGetter: NonNullable<ContextDetails>['removeFileGetter'] = useCallback(
		(name) => {
			const { [name]: remove, ...everythingElse } = fileGetters.current;
			
			fileGetters.current = everythingElse;
			
			clearErrors(name);
		},
		[clearErrors]
	);
	
	
	// Add a token getter
	const addTokenGetter: NonNullable<ContextDetails>['addTokenGetter'] = useCallback((name, getter) => {
		if (valueGetters.current[name] !== undefined || tokenGetters.current[name] !== undefined) {
			throw new Error(`Field names must be unique: ${name} already exists`);
		}
		
		tokenGetters.current[name] = getter;
	}, []);
	
	
	// Remove a token getter and associated errors
	const removeTokenGetter: NonNullable<ContextDetails>['removeTokenGetter'] = useCallback(
		(name) => {
			const { [name]: remove, ...everythingElse } = tokenGetters.current;
			
			tokenGetters.current = everythingElse;
			
			clearErrors(name);
		},
		[clearErrors]
	);
	
	
	// Reusable function to get tokens
	const getTokens = async () => {
		// Initialize
		const tokens: ParametersOrFiles = {};
		
		
		// Loop over getters
		for (const name of Object.keys(tokenGetters.current)) {
			set(tokens, name, await tokenGetters.current[name]());
		}
		
		
		// Return
		return tokens;
	};
	
	
	// Add an error container
	const addErrorContainer: NonNullable<ContextDetails>['addErrorContainer'] = useCallback((name) => {
		if (errorContainers.current.has(name)) {
			throw new Error(`Only one error container per name is allowed: ${name} is already taken`);
		}
		
		errorContainers.current.add(name);
	}, []);
	
	
	// Remove an error container and associated errors
	const removeErrorContainer: NonNullable<ContextDetails>['removeErrorContainer'] = useCallback(
		(name) => {
			errorContainers.current.delete(name);
			
			clearErrors(name);
		},
		[clearErrors]
	);
	
	
	// Keep track of the number of changes that prevent navigation
	const changesThatPreventNavigation = useRef(0);
	
	
	// Handle changes to any field
	const handleChange = (shouldPreventNavigation?: true) => {
		// If this change should prevent navigation, count it
		if (shouldPreventNavigation) {
			changesThatPreventNavigation.current += 1;
			
			if (changesThatPreventNavigation.current >= 25 && !warnBeforeNavigation) {
				setWarnBeforeNavigation(true);
			}
		}
		
		
		// Do nothing if no function given by parent
		if (typeof onChange !== 'function') {
			return;
		}
		
		
		// Get values/files
		const { values, files } = getValuesAndFiles();
		
		
		// Call handler
		onChange(values, files);
	};
	
	
	// Use React Router functionality
	usePrompt({
		when: !options.navigationPrompt?.disabled && !isLoading && warnBeforeNavigation,
		message:
			options.navigationPrompt?.message ??
			'Changes were made to this form, are you sure you want to navigate away from this page?',
	});
	
	
	// Use Redux functionality
	const dispatch = useAppDispatch();
	
	
	// Use reCAPTCHA functionality
	const {
		v2ChallengeActive,
		getRecaptchaHeaders,
		recaptchaV2,
		resetRecaptchaWidget,
		deactivateRecaptchaChallenge,
		challengeV2JSX,
		recaptchaLegalNoticeJSX,
	} = useRecaptcha({
		...options.recaptcha,
		appearsOnBackground: options.appearsOnBackground,
		id: `${options.action}-${options.uri}`,
		setIsLoading,
	});
	
	
	// Handle API call
	const handleAPICall = () => {
		// Clear errors
		clearErrors();
		
		
		// Wrap in try/catch
		try {
			// Get parameters/files
			const { values: originalParameters, files: originalFiles } = getValuesAndFiles();
			
			
			// Get tokens
			Promise.all([getTokens(), getRecaptchaHeaders()])
				.then(([tokens, recaptchaHeaders]) => {
					// Merge parameters, tokens, and extra parameters
					let mergedParameters = merge({}, originalParameters, tokens, options.extraParameters);
					
					
					// Merge files and extra files
					const mergedFiles = merge({}, originalFiles, options.extraFiles);
					
					
					// Replace identifiers in the URI with form field values
					let uri = options.uri;
					const matches = uri.match(/\[[a-zA-Z0-9-_.[\]]+]/g);
					
					if (matches) {
						// Loop over each matching segment
						for (const match of matches) {
							// Remove the square brackets
							const trimmedMatch = match.replace(/[[\]]/g, '');
							
							
							// Make replacement
							const replacementSchema = z.string();
							
							uri = uri.replace(match, replacementSchema.parse(get(mergedParameters, trimmedMatch)));
							
							
							// Remove from parameters
							mergedParameters = omit(mergedParameters, trimmedMatch) as Partial<Parameters>;
						}
					}
					
					
					// Prune empty things
					const { parameters, files } = pruneParametersAndFiles(mergedParameters, mergedFiles);
					
					
					// Call API
					void dispatch(
						apiActions.call<Parameters, Files, Response>({
							action: options.action,
							uri: uri,
							headers: recaptchaHeaders,
							parameters: parameters,
							files: files,
							handleErrorsAutomatically: false,
							recaptchaV2,
							completion: (json) => {
								// Handling for errors
								if (json.status === 'error') {
									// Reset back to not loading
									if (isMounted.current) {
										setIsLoading(false);
									}
									
									
									// Check for any custom error handlers
									if (Array.isArray(options.errorHandlers)) {
										// Loop over each
										for (const handler of options.errorHandlers) {
											// Check for match
											let matches = true;
											
											for (const [untypedKey, value] of Object.entries(handler.match)) {
												// Strictly type `key` for TypeScript (https://github.com/microsoft/TypeScript/pull/12253#issuecomment-263132208)
												const key = untypedKey as keyof typeof handler.match;
												
												if (json[key] !== value) {
													matches = false;
													break;
												}
											}
											
											
											// Handle a match
											if (matches) {
												handler.handler(json);
												return;
											}
										}
									}
									
									
									// Display parameter/file error message if there is one
									if (json.parameter || json.file) {
										const prunedPath = (json.parameter || json.file) ?? '';
										const unprunedObject = json.parameter ? mergedParameters : mergedFiles;
										const unprunedPath = determineUnprunedPath(
											prunedPath,
											unprunedObject,
											json.parameter
												? (object) =>
														helperFunctions.pruneEmptyObjectsAndArrays(helperFunctions.pruneEmptyStrings(object))
												: (object) => helperFunctions.pruneEmptyObjectsAndArrays(object)
										);
										
										if (
											Object.prototype.hasOwnProperty.call(valueGetters.current, unprunedPath) ||
											Object.prototype.hasOwnProperty.call(fileGetters.current, unprunedPath) ||
											Object.prototype.hasOwnProperty.call(tokenGetters.current, unprunedPath) ||
											errorContainers.current.has(unprunedPath)
										) {
											setError(unprunedPath, json.message || '');
										} else {
											dispatch(
												commonActions.showPageMessage({
													color: 'danger',
													title: 'Unexpected error',
													message: (
														<React.Fragment>
															{unprunedPath}: <span dangerouslySetInnerHTML={{ __html: parseMarkdown(json.message) }} />
														</React.Fragment>
													),
												})
											);
										}
										
										return;
									}
									
									
									// Handle all other issues
									dispatch(
										commonActions.showPageMessage({
											color: 'danger',
											title: 'Oh snap!',
											message: <span dangerouslySetInnerHTML={{ __html: parseMarkdown(json.message) }} />,
										})
									);
									
									
									// Return
									return;
								}
								
								
								// Handle success
								if (typeof onCompletion === 'function') {
									// Call completion handler
									const response = onCompletion(json, parameters as Parameters);
									
									
									// If we received a promise in response, wait for it to finish before resetting back to not loading
									if (response instanceof Promise) {
										response
											.then(() => {
												if (isMounted.current) {
													setIsLoading(false);
													changesThatPreventNavigation.current = 0;
													setWarnBeforeNavigation(false);
												}
											})
											.catch((problem: unknown) => {
												// Report to Sentry
												Sentry.captureException(problem);
												
												
												// Show page error
												dispatch(
													commonActions.showPageMessage({
														color: 'danger',
														title: 'Oh snap!',
														message:
															problem instanceof Error && problem.message ? (
																<span dangerouslySetInnerHTML={{ __html: parseMarkdown(problem.message) }} />
															) : (
																'There was an unexpected issue.'
															),
													})
												);
												
												
												// Reset back to not loading
												if (isMounted.current) {
													setIsLoading(false);
												}
											});
										
										return;
									}
								}
								
								
								// Otherwise, reset immediately
								if (isMounted.current) {
									deactivateRecaptchaChallenge();
									
									setIsLoading(false);
									changesThatPreventNavigation.current = 0;
									setWarnBeforeNavigation(false);
								}
							},
							...options.customCallSettings,
							callBeforeFinish: () => {
								resetRecaptchaWidget();
								
								return options.customCallSettings?.callBeforeFinish?.();
							},
						})
					);
				})
				.catch((problem) => {
					// Send to Sentry
					if (!(problem instanceof ExpectedFormError)) {
						Sentry.captureException(problem);
					}
					
					
					// Reset back to not loading
					if (isMounted.current) {
						setIsLoading(false);
					}
				});
		} catch (problem) {
			// Log error during development
			if (process.env.NODE_ENV === 'development') {
				console.error(problem);
			}
			
			
			// Send to Sentry
			Sentry.captureException(problem);
			
			
			// Show page error
			dispatch(
				commonActions.showPageMessage({
					color: 'danger',
					title: 'Oh snap!',
					message: 'There was an unexpected issue.',
				})
			);
			
			
			// Reset back to not loading
			if (isMounted.current) {
				setIsLoading(false);
			}
		}
	};
	
	
	// Use warning modal
	const warningModal = useWarningModal({
		enabled: Boolean(options.warning),
		onConfirmation: handleAPICall,
		onCancel: () => {
			if (isMounted.current) {
				setIsLoading(false);
			}
		},
		...options.warning,
	});
	
	
	// Handle form submissions
	const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
		// Prevent the form's default submission behavior
		event?.preventDefault();
		
		
		// Set loading state
		setIsLoading(true);
		
		
		// Handle warning confirmation
		if (warningModal.open()) {
			// No further processing
			return;
		}
		
		
		// Call API
		handleAPICall();
	};
	
	
	// Form submit button JSX
	const submitButtonJSX = (
		<Button
			type='submit'
			disabled={isLoading || options.submitButton.disabled}
			isLoading={isLoading}
			color={options.submitButton.color}
			aria-label={options.submitButton.ariaLabel}
			border={options.submitButton.color === 'plain'}
			smaller={options.submitButton.size === 'smaller' ? true : undefined}
			smallest={options.submitButton.size === 'smallest' ? true : undefined}
			useBackgroundColorWithFocus={options.appearsOnBackground ?? true}
		>
			{options.submitButton.text}
		</Button>
	);
	
	
	// Return JSX
	return (
		<APIConnectedFormContext.Provider
			value={{
				addValueGetter,
				addFileGetter,
				addTokenGetter,
				removeValueGetter,
				removeFileGetter,
				removeTokenGetter,
				addErrorContainer,
				removeErrorContainer,
				setError,
				clearErrors,
				error: {
					field: errorField,
					message: errorMessage,
				},
				onChange: handleChange,
				onExcludeChange: handleChange,
				recoverState: {
					enabled: options.recoverState === undefined ? true : Boolean(options.recoverState),
					uniqueIdentifier: `${options.action} ${options.uri}`,
				},
				v2ChallengeActive,
			}}
		>
			{options.recaptcha?.enabled && <GlobalAPIFormStyle />}
			
			<Wrapper onSubmit={handleSubmit} ref={ref} className={className}>
				{children}
				
				{challengeV2JSX}
				
				<ThemeProvider
					theme={{
						boxShadow: options.appearsOnBackground ?? true ? '0 0.125em 0.25em rgba(0, 0, 0, 0.05)' : null,
					}}
				>
					<ButtonGroup>
						{options.submitButton.tooltip ? (
							<Tippy {...tippyProps} content={options.submitButton.tooltip}>
								{submitButtonJSX}
							</Tippy>
						) : (
							submitButtonJSX
						)}
						
						{typeof onCancel === 'function' ? (
							<ThemeProvider theme={{ boxShadow: null }}>
								<Button
									type='button'
									color='background'
									disabled={isLoading}
									onClick={onCancel}
									inverted
									link
									useBackgroundColorWithFocus={options.appearsOnBackground ?? true}
								>
									{(options.cancelButton && options.cancelButton.text) || 'Cancel'}
								</Button>
							</ThemeProvider>
						) : null}
						
						{options.extraControls}
					</ButtonGroup>
				</ThemeProvider>
				
				{recaptchaLegalNoticeJSX}
				
				{warningModal.component}
			</Wrapper>
		</APIConnectedFormContext.Provider>
	);
});

export default APIForm;
