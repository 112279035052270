// Imports
import React, { useState, useRef, useEffect } from 'react';
import { useAppDispatch } from '../../../../foundation/front-end/redux/hooks';
import apiActions from '../../../../../shareables/integrations/api/redux/actions';
import Button, { StylingProps } from '../../../../../shareables/foundation/front-end/components/buttons/standard';
import { APICallWithoutAutoErrorHandling } from '../../../../../shareables/integrations/api/redux/types';
import pruneParametersAndFiles from '../utils/prune-parameters-and-files';
import useWarningModal, { WarningModalOptions } from '../utils/use-warning-modal';
import Tippy from '@tippyjs/react';
import tippyProps from '../../../../foundation/front-end/utils/tippy-props';
import commonActions from '../../../../foundation/front-end/redux/actions';
import * as Sentry from '@sentry/browser';
import parseMarkdown from '../../../../utils/markdown/parse';


// Define the accepted props
export interface APIButtonOptions<Parameters, Files, Response> {
	/** The action portion of the API endpoint (all caps). */
	action: string;
	
	
	/** The URI portion of the API endpoint, with a leading forward slash (`/`). */
	uri: string;
	
	
	/** A tooltip to show when someone hovers over the button. */
	tooltip?: string;
	
	
	/** If provided, a warning will be shown before submitting, asking the user to confirm their action. */
	warning?: Omit<WarningModalOptions, 'enabled' | 'onConfirmation' | 'onCancel'>;
	
	
	/** Any of the call function’s parameters that you want to override. */
	customCallSettings?: Partial<APICallWithoutAutoErrorHandling<Parameters, Files, Response>>;
}

interface BaseProps<Parameters, Files, Response> {
	/** An object that governs how the button behaves. */
	options: APIButtonOptions<Parameters, Files, Response>;
	
	
	/** Parameters to pass to the API. */
	parameters?: Parameters;
	
	
	/** Files to pass to the API. */
	files?: Files;
	
	
	/** A function that handles successful API calls. The function is passed the parsed JSON as the first parameter and the original parameters as the second parameter. If the function returns a promise, the button will wait for that promise to resolve before resetting the “loading” state back to `false`. */
	onCompletion?: (json: SuccessfulAPIResponse<Response>, parameters: Parameters) => void | Promise<void>;
}

type Props<Parameters, Files, Response> = BaseProps<Parameters, Files, Response> &
	StylingProps &
	Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'>;


// Function component
const APIButton = React.forwardRef(function APIButtonInner<
	Parameters = undefined,
	Files = undefined,
	Response = undefined,
>(
	{
		options,
		parameters: originalParameters,
		files: originalFiles,
		onCompletion,
		children,
		...props
	}: Props<Parameters, Files, Response>,
	ref: React.ForwardedRef<HTMLButtonElement>
): React.ReactElement {
	// Use state
	const [isLoading, setIsLoading] = useState(false);
	
	
	// Used to detect mount status
	const isMounted = useRef(true);
	
	useEffect(() => {
		isMounted.current = true;
		
		return () => {
			isMounted.current = false;
		};
	});
	
	
	// Use Redux functionality
	const dispatch = useAppDispatch();
	
	
	// Handle API call
	const handleAPICall = () => {
		// Wrap in try/catch
		try {
			// Prune empty things
			const { parameters, files } = pruneParametersAndFiles(originalParameters ?? {}, originalFiles ?? {});
			
			
			// Call API
			void dispatch(
				apiActions.call<Parameters, Files, Response>({
					action: options.action,
					uri: options.uri,
					parameters,
					files,
					handleErrorsAutomatically: false,
					completion: (json) => {
						// Handling for errors
						if (json.status === 'error') {
							// Show page message
							dispatch(
								commonActions.showPageMessage({
									color: 'danger',
									title: 'Oh snap!',
									message: <span dangerouslySetInnerHTML={{ __html: parseMarkdown(json.message) }} />,
								})
							);
							
							
							// Reset back to not loading
							if (isMounted.current) {
								setIsLoading(false);
							}
							
							
							// Return
							return;
						}
						
						
						// Call completion handler
						const response = onCompletion?.(json, parameters as Parameters);
						
						
						// If we received a promise in response, wait for it to finish before resetting back to not loading
						if (response instanceof Promise) {
							response
								.then(() => {
									if (isMounted.current) {
										setIsLoading(false);
									}
								})
								.catch((problem: unknown) => {
									// Report to Sentry
									Sentry.captureException(problem);
									
									
									// Show page error
									dispatch(
										commonActions.showPageMessage({
											color: 'danger',
											title: 'Oh snap!',
											message:
												problem instanceof Error && problem.message ? (
													<span dangerouslySetInnerHTML={{ __html: parseMarkdown(problem.message) }} />
												) : (
													'There was an unexpected issue.'
												),
										})
									);
									
									
									// Reset back to not loading
									if (isMounted.current) {
										setIsLoading(false);
									}
								});
							
							return;
						}
						
						
						// Otherwise reset immediately
						if (isMounted.current) {
							setIsLoading(false);
						}
					},
					...options.customCallSettings,
				})
			);
		} catch (problem) {
			// Log error during development
			if (process.env.NODE_ENV === 'development') {
				console.error(problem);
			}
			
			
			// Send to Sentry
			Sentry.captureException(problem);
			
			
			// Show page error
			dispatch(
				commonActions.showPageMessage({
					color: 'danger',
					title: 'Oh snap!',
					message: 'There was an unexpected issue.',
				})
			);
		}
	};
	
	
	// Use warning modal
	const warningModal = useWarningModal({
		enabled: Boolean(options.warning),
		onConfirmation: handleAPICall,
		onCancel: () => {
			if (isMounted.current) {
				setIsLoading(false);
			}
		},
		...options.warning,
	});
	
	
	// Handle button clicks
	const handleClick = () => {
		// Set is loading
		setIsLoading(true);
		
		
		// Handle warning confirmation
		if (warningModal.open()) {
			// No further processing
			return;
		}
		
		
		// Call API
		handleAPICall();
	};
	
	
	// Button JSX
	const buttonJSX = (
		<Button
			type='button'
			ref={ref}
			isLoading={isLoading}
			onClick={handleClick}
			disabled={isLoading || props.disabled}
			{...props}
		>
			{children}
		</Button>
	);
	
	
	// Return JSX
	return (
		<React.Fragment>
			{options.tooltip ? (
				<Tippy {...tippyProps} content={options.tooltip}>
					{buttonJSX}
				</Tippy>
			) : (
				buttonJSX
			)}
			
			{warningModal.component}
		</React.Fragment>
	);
});

export default APIButton;
